<template>
  <!-- eslint-disable -->
  <div class="flex-lg-row-fluid ms-lg-10">
    <div class="card mb-5 mb-lg-10">
      <div class="card-header">
        <div class="card-title">
          <h3>Użytkownicy</h3>
        </div>
        <router-link :to="'/admin/organisations/'+id+'/users/add'" v-if="profile.is_admin" class="btn btn-primary align-self-center btn-sm">Dodaj użytkownika</router-link>
        <router-link :to="'/dashboard/users/add'" v-else class="btn btn-primary align-self-center btn-sm">Dodaj użytkownika</router-link>
      </div>
      <div class="card-body py-0">
        <div class="table-responsive">
          <table class="table align-middle table-row-bordered table-row-dashed gy-5" id="kt_table_widget_1">
            <tbody>
              <tr class="text-start text-gray-400 fw-boldest fs-7 text-uppercase">
                <th class="min-w-125px px-0">Imię i nazwisko</th>
                <th class="min-w-125px">E-mail</th>
                <th class="min-w-100px text-center">&nbsp;</th>
              </tr>
              <tr v-for="user in users">
                <td class="p-0 align-top pt-5 pb-5">
                  <div class="d-flex align-items-center">
                    <div class="">
                      <router-link v-if="profile.is_admin" :to="'/admin/organisations/'+id+'/users/'+user.uuid" class="text-gray-800 fw-boldest fs-6 text-hover-primary mb-1">
                        {{ user.first_name }} {{ user.last_name }}
                      </router-link>
                      <router-link v-else :to="'/dashboard/users/'+user.uuid" class="text-gray-800 fw-boldest fs-6 text-hover-primary mb-1">
                        {{ user.first_name }} {{ user.last_name }}
                      </router-link>
                    </div>
                  </div>
                </td>
                <td class="align-top pt-5 pb-5">
                  <div class="d-flex align-items-center">
                    {{ user.email }}
                  </div>
                </td>
                <td class="align-top pt-5 pb-5 text-center">
                  &nbsp;
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Users',
  inject: ['$profile', '$organisation'],
  props: ['id'],
  data() {
    return {
      timer: false,
      users: []
    }
  },
  mounted () {
      this.reload();
      this.timer = setInterval(this.reload, 60000);
  },
  methods: {
    reload() {
      var self = this;
      var id = false;
      if (this.id)
      {
        id = this.id;
      }
      else if (this.organisation && this.organisation.uuid)
      {
        id = this.organisation.uuid;
      }
      else
      {
        return;
      }
      this.$root.$refs.api.get('organisations/' + id + '/users').then(function (response) {
        if (typeof response.data !== 'undefined')
        {
          self.users = response.data;
        }
      })
      .catch(function (error) {
        console.log(error);
        self.users = [];
      });
    },
    cancelAutoUpdate() {
      clearInterval(this.timer);
    },
  },
  beforeUnmount() {
    this.cancelAutoUpdate();
  },
  computed: {
    organisation() {
      return this.$organisation();
    },
    profile() {
      return this.$profile();
    }
  }

};
</script>
